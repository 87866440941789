import React, { ReactNode, useEffect, useState } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link, 
  Text,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Input,
  ModalFooter,
  Button,
  useToast
} from '@chakra-ui/react';
import {
  FiMenu,
  FiChevronDown,
  FiUsers
} from 'react-icons/fi';
import { TbReportSearch } from "react-icons/tb";
import { GrServices } from "react-icons/gr";
import { LuSettings } from "react-icons/lu";
import { CgReadme } from "react-icons/cg";

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import apiManager from '../apiManager';
import { useProfile } from '../hooks/profileContext';


interface LinkItemProps {
  name: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  path: string;
  onClick?: () => void;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Screenings', icon: TbReportSearch , path: '/zenoti/screening'},
  { name: 'Zenoti Centers', icon: FiUsers , path: '/zenoti/centers'},
  { name: 'Zenoti Services', icon: GrServices, path: '/zenoti/services' },
  { name: 'Settings', icon: LuSettings , path: '/zenoti/settings'},
  { 
    name: 'Learn More', 
    icon: CgReadme, 
    // path: '/zenoti/',
    onClick: () => window.open('https://zenoti-qualiphy.zendesk.com/hc/en-us/articles/30275522712091-How-Do-I-Setup-my-Zenoti-account-with-Qualiphy', '_blank') 
  }

];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const location = useLocation();

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('#3D2B64', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <img 
        src="/logo.png" 
        alt="Qualiphy Logo" 
        style={{ height: "50px", width: "auto" }}
      />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      
      {/* {LinkItems.map((link) => (
        <Link
          as={RouterLink} // Use RouterLink for navigation
          to={link.path}
          key={link.name}
          style={{ textDecoration: 'none' }}
        >
          <NavItem icon={link.icon} active={location.pathname === link.path} >
            {link.name}
          </NavItem>
        </Link>
      ))} */}

      {LinkItems.map((link) => (
        <Link
          as={RouterLink}
          to={link.path || '#'}
          key={link.name}
          style={{ textDecoration: 'none' }}
          onClick={(e) => {
            if (link.onClick) {
              e.preventDefault(); // Prevent default navigation
              link.onClick();
            }
          }}
        >
          <NavItem 
            icon={link.icon} 
            active={location.pathname === link.path}
          >
            {link.name}
          </NavItem>
        </Link>
      ))}

    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  children: ReactNode;
  active?: boolean;
}

const NavItem = ({ icon, children, active, ...rest }: NavItemProps) => {
  return (
    <Link href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={active ? '#261C3A' : 'transparent'} // Active state background color
        color={active ? '#7F56D9' : 'white'} // Active state text color
        _hover={{
          bg: '#261C3A', // Hover background color #261C3A
          color: '#7F56D9', // Hover text color
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            color={active ? '#7F56D9' : 'white'} // Icon color for active state
            _groupHover={{
              color: '#7F56D9', // Icon color on hover
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};


interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  // const [profile, setProfile] = useState({});
  const { profile, fetchProfile } = useProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState({});
  const toast = useToast();
  
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/zenoti/login');
  };

  useEffect(() => {
    fetchProfile();
    if (profile) {
    }else{
      fetchProfile();
    }
  }, [profile, fetchProfile]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setOldPassword("");
    setNewPassword("");
  };

  const validate = () => {
    const newErrors = {};
    if (!oldPassword) {
      newErrors.oldPassword = "Old password is required.";
    } else if (oldPassword.length < 8 || /\s/.test(oldPassword)) {
      newErrors.oldPassword =
        "Old password must be at least 8 characters and cannot contain spaces.";
    }

    if (!newPassword) {
      newErrors.newPassword = "New password is required.";
    } else if (newPassword.length < 8 || /\s/.test(newPassword)) {
      newErrors.newPassword =
        "New password must be at least 8 characters and cannot contain spaces.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

        const payload = {
          oldPassword: oldPassword,
          newPassword: newPassword,
        };

    try {
    
      const response = await apiManager.post(`/zenoti/resetPassword`,payload, true);
      console.log('OLD PASS ajdabda ad',response )
      if(response.message === "Password updated successfully") {
        toast({
          title: "Password Updated Successful",
          description: "Password have been updated successfully!",
          status: "success",
          duration: 1000, // 3 seconds
          isClosable: true,
        });

        closeModal();
      }

      // alert("Password reset successfully!");
      // closeModal();
    } catch (error) {
      console.log('EXCEP', error);
      if (error?.response.data.error === "Old password is incorrect") {
        toast({
          title: error?.response?.data?.error,
          description: "Old password is incorrect",
          status: "error",
          duration: 2000, // 3 seconds
          isClosable: true,
        });
      }
     
      // alert("An error occurred while resetting the password.");
    }
  };



  return (
    <>
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        Logo
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
     
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
              <Text fontSize="sm">Welcome {profile?.firstName}</Text>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  
                  {/* <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text> */}
                   {/* <Avatar
                  size={'sm'}
                  src={
                    // 'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                    profile.image
                  }
                /> */}
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
                 <MenuItem onClick={openModal}>Change Password</MenuItem>
              {/* <MenuItem>Settings</MenuItem> */}
              {/* <MenuDivider /> */}
              <MenuItem onClick={handleLogout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>

    

  <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent maxWidth="500px">
          <ModalHeader>Change Password</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={4}>
              <Input
                type="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              {errors.oldPassword && (
                <Text color="red.500" fontSize="sm">
                  {errors.oldPassword}
                </Text>
              )}

              <Input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errors.newPassword && (
                <Text color="red.500" fontSize="sm">
                  {errors.newPassword}
                </Text>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeModal} variant="outline" mr={3}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
              isDisabled={!oldPassword || !newPassword}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </>
  );
};

export { SidebarContent, NavItem, MobileNav };
