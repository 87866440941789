import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Drawer,
  DrawerContent,
  useDisclosure,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Badge,
} from "@chakra-ui/react";
import { FiSearch, FiPlus, FiEdit2 } from "react-icons/fi";
import { PiDotsThreeCircleVertical } from "react-icons/pi";
import { MdOutlineRemoveCircleOutline, MdBlock, MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SidebarContent, MobileNav } from "./Sidebar";
import apiManager from "../apiManager";

const Centers = () => {
  const [existingCenters, setExistingCenters] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zenotiCenters, setZenotiCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [isEditMode, setIsEditMode] = useState("");
  const [apiKey, setApiKey] = useState("");

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeactivateOpen, setIsDeactivateOpen] = useState(false);
  const [existCenterIdToDelete, setExistCenterIdToDelete] = useState(null);

  const cancelRef = React.createRef();

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiManager.get(
          `/zenoti/centers?page=${currentPage}&limit=10&searchName=${searchQuery}`,
          true
        );

        if (response.docs && response.docs.length > 0) {
          setExistingCenters(response.docs);
          setTotalPages(response.totalPages);
        } else {
          setExistingCenters([]);
          setTotalPages(0);
          setError("No Centers found.");
        }
        setIsLoading(false);
      } catch (error) {
        // setError(error.message || "Failed to fetch Centers.");
        setError("No Centers found.");
        setIsLoading(false);
      }
    };

    const fetchZenotiCenters = async () => {
      try {
        const response = await apiManager.get("/zenoti/getzenoticenters", true);

        if (response.body.centers) {
          setZenotiCenters(response.body.centers);
        }
      } catch (err) {
        console.error("Failed to fetch Zenoti Centers:", err);
      }
    };

    fetchData();
    fetchZenotiCenters();
  }, [currentPage, searchQuery]);

  const handleEditCenter = (existingcentersData) => {
    // navigate('/edit-center', { state: { existingcentersData, isFetch: true } });
  };

  const handleRemoveExistingCenter = (centerId) => {
    setExistCenterIdToDelete(centerId);
    setIsDeleteOpen(true);
  };

  const handleDeactivateExistingCenter = (centerId) => {
    setExistCenterIdToDelete(centerId);
    setIsDeactivateOpen(true);
  };

  // const openAddCenterModal = () => setIsModalOpen(true);
  const openAddCenterModal = () => {
    setIsEditMode(false); // Set to Add mode
    setIsModalOpen(true); // Open the modal
  };

  // Function to open modal in Edit mode
  const openEditModal = (center) => {
    setIsEditMode(true); // Set to Edit mode
    setSelectedCenter(center); // Set the center to be edited
    setApiKey(center.qualiphyApiKey || ""); // Pre-fill the API key if available
    setIsModalOpen(true);
  };

  const closeAddCenterModal = () => {
    setSelectedCenter("");
    setApiKey("");
    setIsModalOpen(false);
  };

  const handleAddCenter = async () => {
    if (isEditMode) {
      const payload = {
        id: selectedCenter?.id,
        qualiphyApiKey: apiKey || null,
      };

      
      try {
        await apiManager.put("/zenoti/updateCenter", payload, true);
        setIsModalOpen(false);
        window.location.reload(); // Refresh the list
      } catch (err) {
        console.error("Failed to add center:", err);
      }
    } else {
      const payload = {
        zenotiCenterId: selectedCenter?.id,
        zenotiCenterName: selectedCenter?.name,
        qualiphyApiKey: apiKey || null,
      };
      
      try {
        let addResponse = await apiManager.post("/zenoti/addcenter", payload, true);
        console.log('addResponse', addResponse)
       
        setIsModalOpen(false);
        window.location.reload(); // Refresh the list
      } catch (err) {
        if(err.response.data.message === "This center has already been added!"){
          console.log('ERROR', error)
            setError(err.response.data.message);

                  // Clear the error after 5 seconds
          setTimeout(() => {
            setError("");
          }, 2000);
        }
        console.error("Failed to add center:", err);
      }
    }
  };

  const onDeleteConfirm = async () => {
    let payload = {
      id: existCenterIdToDelete.id,
    };
    
    try {
      const response = await apiManager.delete(
        `/zenoti/deletecenter`,
        payload,
        true
      );
      if (response.success === true) {
        window.location.reload();
      } else {
        setError("Failed to delete center.");
      }
    } catch (error) {
      setError(error.message || "Failed to delete center.");
    }
    setIsDeleteOpen(false);
  };

  const onDeactivateConfirm = async () => {
    let payload = {
      id: existCenterIdToDelete.id,
      deactive: true
    };

    
    
    try {
      const response = await apiManager.put("/zenoti/updateCenter", payload, true);
      if (response.success === true) {
        window.location.reload();
      } else {
        setError("Failed to delete center.");
      }
    } catch (error) {
      setError(error.message || "Failed to delete center.");
    }
    setIsDeleteOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  return (
    <Box minH="100vh" bg="#F4F4F4">
      <SidebarContent
        onClose={onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p="6">
        <Flex justify="space-between" align="center" mb={6}>
          <Text fontSize="3xl" fontWeight="bold">
            Zenoti Centers
          </Text>
          <Button
            leftIcon={<FiPlus />}
            bg="#52307C"
            color="white"
            _hover={{ bg: "#3E2562" }}
            onClick={openAddCenterModal}
          >
            Add Center
          </Button>
        </Flex>

        {/* <Flex justify="space-between" mb={6} gap={4}>
          <InputGroup maxW="320px">
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Search..."
              bg="white"
              border="1px solid"
              borderColor="gray.200"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>

        </Flex> */}

        <Box bg="white" borderRadius="md" overflow="hidden" boxShadow="sm">
          {isLoading ? (
            <Flex justify="center" align="center" h="200px">
              <Spinner size="xl" />
            </Flex>
          ) : existingCenters.length > 0 ? (
            <Table variant="simple">
              <Thead bg="#E4E1F5" height="60px">
                <Tr>
                  <Th>Id</Th>
                  <Th>Center Name</Th>
                  <Th>Center ID</Th>
                  <Th>Qualiphy API Key</Th>
                  <Th>Status</Th>
                  <Th>Date Added</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {existingCenters.map((center) => (
                  <Tr key={center.id}>
                    <Td>{center.id}</Td>
                    <Td>{center.zenotiCenterName}</Td>
                    <Td>{center.zenotiCenterId}</Td>
                    <Td>{center.qualiphyApiKey}</Td>
                    <Td>
                      {center?.isActive === true ? (
                        <Badge colorScheme="green" px="2" py="1" borderRadius="md">
                          Active
                        </Badge>
                      ) : (
                        <Badge colorScheme="red" px="2" py="1" borderRadius="md">
                          Inactive
                        </Badge>
                        // <Badge colorScheme="red" px="2" py="1" borderRadius="md">
                        //   Deactive
                        // </Badge>
                      )}
                    </Td>
                    <Td>{center.createdAt}</Td>
                   
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={
                            <PiDotsThreeCircleVertical
                              style={{ fontSize: "24px" }}
                            />
                          }
                          aria-label="Options"
                          variant="unstyled"
                          _hover={{ bg: "white" }}
                          //   _focus={{ boxShadow: "outline" }}
                        />
                        <MenuList
                          bg="white"
                          boxShadow="lg"
                          borderRadius="md"
                          py={2}
                          minWidth="150px"
                        >
                          <MenuItem
                            onClick={() => openEditModal(center)}
                            icon={<FiEdit2 />}
                            _hover={{ bg: "gray.50" }}
                            fontWeight="medium"
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleRemoveExistingCenter(center)}
                            icon={<MdOutlineRemoveCircleOutline />}
                            _hover={{ bg: "red.50", color: "red.500" }}
                            fontWeight="medium"
                          >
                            Remove
                          </MenuItem>
                          {/* <MenuItem
                            onClick={() =>
                              handleDeactivateExistingCenter(center)
                            }
                            icon={<MdBlock />}
                            _hover={{ bg: "yellow.50", color: "yellow.600" }}
                            fontWeight="medium"
                          >
                            Deactivate
                          </MenuItem> */}
                      <MenuItem
                          onClick={() =>
                            center.isActive
                              ? handleDeactivateExistingCenter(center)
                              : openEditModal(center)
                          }
                          icon={center.isActive ? <MdBlock /> : <MdCheck />}
                          _hover={{ bg: center.isActive ? "yellow.50" : "green.50", color: center.isActive ? "yellow.600" : "green.600" }}
                          fontWeight="medium"
                        >
                          {center.isActive ? "Inactivate" : "Activate"}
                        </MenuItem>


                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex justify="center" align="center" h="200px">
              <Text>{error || "No Centers found."}</Text>
            </Flex>
          )}
        </Box>

        <Flex
          p={4}
          justify="center"
          align="center"
          borderTop="1px"
          borderColor="gray.200"
        >
          <ButtonGroup variant="outline" spacing={1}>
            <Button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
              size="sm"
            >
              Prev
            </Button>
            {[...Array(totalPages)].map((_, i) => (
              <Button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                bg={currentPage === i + 1 ? "#52307C" : "transparent"}
                color={currentPage === i + 1 ? "white" : "black"}
                size="sm"
              >
                {i + 1}
              </Button>
            ))}
            <Button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              isDisabled={currentPage === totalPages}
              size="sm"
            >
              Next
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>

      <Modal isOpen={isModalOpen} onClose={closeAddCenterModal}>
        <ModalOverlay />
        <ModalContent
          margin="auto"
          transform="translateY(-50%)"
          maxWidth="500px"
        >
          <ModalHeader>
            {isEditMode ? "Edit Zenoti Center" : "Add Zenoti Center"}
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={4}>
              {isEditMode ? (
                <>
                  {/* Display the selected center's name in Edit mode in a disabled Select dropdown */}
                  <Select
                    placeholder="Select Zenoti Center"
                    value={selectedCenter?.id || ""}
                    isDisabled // Disable the dropdown to prevent changes
                  >
                    {/* Only show the selected center as a single option */}
                    <option value={selectedCenter?.id} disabled>
                      {selectedCenter?.zenotiCenterName}
                    </option>
                  </Select>                
                  {/* API Key input for Edit mode */}
                  <Input
                    placeholder="Qualiphy API Key"
                    // value={selectedCenter?.qualiphyApiKey || ""}
                    value={apiKey} // Bind to the dynamic state
                    onChange={(e) => setApiKey(e.target.value)} // Allow API Key edit
                  />
                </>
              ) : (
                <>
                  {/* Show center selection in Add mode */}
                  <Select
                    placeholder="Select Zenoti Center"
                    value={selectedCenter?.id || ""}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const center = zenotiCenters.find(
                        (c) => c.id === selectedId
                      );
                      setSelectedCenter(center); // Set the selected center object
                    }}
                  >
                    {zenotiCenters.map((center) => (
                      <option key={center.id} value={center.id}>
                        {center.name}
                      </option>
                    ))}
                  </Select>
                  
                  <Input
                    placeholder="Qualiphy API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)} // Allow API Key input
                  />
                </>
              )}

              {error && (
                <Text color="red.500" fontSize="sm" mt={2}>
                  {error}
                </Text>
                )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button onClick={closeAddCenterModal} variant="outline" mr={3}>
              Cancel
            </Button>
            <Button
              onClick={handleAddCenter}
              bg="#52307C"
              color="white"
              _hover={{ bg: "#3E2562" }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Center
            </AlertDialogHeader>
            <AlertDialogBody>
              {`Are you sure you want to delete ${existCenterIdToDelete?.zenotiCenterName}?`}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>


      <AlertDialog
  isOpen={isDeactivateOpen}
  leastDestructiveRef={cancelRef}
  onClose={() => setIsDeactivateOpen(false)}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Inactivate Center
      </AlertDialogHeader>
      <AlertDialogBody>
        {`Are you sure you want to Inactivate ${existCenterIdToDelete?.zenotiCenterName}?`}
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={() => setIsDeactivateOpen(false)}>
          Cancel
        </Button>
        <Button colorScheme="yellow" onClick={onDeactivateConfirm} ml={3}>
          InActivate
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>

    </Box>
  );
};

export default Centers;
