import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import styled from 'styled-components';
import { Toaster, toast } from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';
import useAuth from './hooks/useAuth';

// Existing components
import Login from './components/Login';
import SignUp from './components/SignUp';
import Screening from './components/Screening';
import Centers from './components/Centers';
import Services from './components/Services';
import Settings from './components/Settings';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';

// Documentation components
import IntroPage from './components/IntroPage';
import IntroPageBeta from './components/IntroPageBeta';
import Header from './components/Header';
import docsService from './services/docs-service';
import Sidebar from './components/SidebarApi';
import EndpointPage from './components/EndpointPage';
// import GettingStarted from './components/GettingStarted';

// Styled components for documentation layout
const Layout = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f4f7fc;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Main = styled.main`
  flex: 1;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px;
  }
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function AppRoutes() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [endpoints, setEndpoints] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await docsService.getDocs();
        if (response?.error) {
          toast.error('Something went wrong. Please try again!');
        } else {
          setEndpoints(response);
        }
      } catch (error) {
        toast.error('Something went wrong. Please try again!');
      } finally {
        setLoading(false);
      }
    };

    fetchDocs();
  }, []);

  // Function to determine if the current path is a docs or beta path
  const isDocsOrBetaRoute = location.pathname.startsWith('/docs') || location.pathname.startsWith('/beta');
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Show Header for docs/beta routes */}
      {isDocsOrBetaRoute && <Header toggleSidebar={toggleSidebar} />}

      <Routes>
        {/* Authentication Routes */}
        <Route path="/zenoti/reset-password/:token" element={<ResetPassword />} />
        <Route path="/zenoti/forgot-password" element={<ForgotPassword />} />
        <Route path="/zenoti/login" element={isAuthenticated ? <Screening /> : <Login />}  />
        <Route path="/zenoti/register" element={ <SignUp />} />
        {/* <Route path="/zenoti/getting/started" element={ <GettingStarted />} /> */}
        
        {/* Protected Routes */}
        <Route path="/zenoti/screening" element={isAuthenticated ? <Screening /> : <Navigate to="/zenoti/login" />} />
        <Route path="/zenoti/centers" element={isAuthenticated ? <Centers /> : <Navigate to="/zenoti/login" />} />
        <Route path="/zenoti/services" element={isAuthenticated ? <Services /> : <Navigate to="/zenoti/login" />} />
        <Route path="/zenoti/settings" element={isAuthenticated ? <Settings /> : <Navigate to="/zenoti/login" />} />
        
        {/* Public Routes */}
       

        {/* Documentation Routes with Layout */}
        {isDocsOrBetaRoute && (
          <Route
            path='*'
            element={
              <Layout>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} endpoints={endpoints} />
                <Main>
                  {loading ? (
                    <LoaderContainer>
                      <ClipLoader size={50} color={'#123abc'} loading={loading} />
                    </LoaderContainer>
                  ) : (
                    <Routes>
                      {/* Docs routes */}
                      <Route path='/docs/api/intro' element={<IntroPage />} />
                      <Route path='/docs/api' element={<IntroPage />} />
                      <Route path='/docs' element={<IntroPage />} />
                      {endpoints
                        .filter((endpoint) => endpoint.isBeta === false)
                        .map((endpoint) => (
                          <Route
                            key={endpoint.id}
                            path={`/docs/api/${endpoint.title.toLowerCase().replace(/ /g, '-')}`}
                            element={<EndpointPage endpoint={endpoint} />}
                          />
                        ))}

                      {/* Beta routes */}
                      <Route path='/beta/api/intro' element={<IntroPageBeta />} />
                      <Route path='/beta/api' element={<IntroPage />} />
                      <Route path='/beta' element={<IntroPage />} />
                      {endpoints
                        .filter((endpoint) => endpoint.isBeta === true)
                        .map((endpoint) => (
                          <Route
                            key={endpoint.id}
                            path={`/beta/api/${endpoint.title.toLowerCase().replace(/ /g, '-')}`}
                            element={<EndpointPage endpoint={endpoint} />}
                          />
                        ))}

                      {/* Fallback for undefined routes */}
                      <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect all other paths to login */}
                    </Routes>
                  )}
                </Main>
              </Layout>
            }
          />
        )}

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/zenoti/login" />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <ChakraProvider>
      <Router>
        <AppRoutes />
      </Router>
    </ChakraProvider>
  );
}

export default App;