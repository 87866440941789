import { Box, Button, Flex, Input, Text, FormLabel, FormControl, Link, Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, Link as RouterLink  } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useAuth from '../hooks/useAuth';
import apiManager from '../apiManager';

const Login = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  

  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    setIsLoading(true);
    try {
      const response = await apiManager.post('/zenoti/login/user', values);
    
      const token = response?.token;
      login(token);
      if(response.message === "Login successful"){
        navigate('/zenoti/screening');
      } 
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const message  = error.response.data.error;
        setFieldError('password', message); // Assuming error message structure matches
      } else {
        console.error('Login error:', error);
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  if (isAuthenticated) {
    navigate('/zenoti/screening');
    return null; // Render nothing if already authenticated and navigating away
  }

  return (
    <Box w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center" bg="#E4E1F5">
      <Flex 
        direction="column" 
        p={10} 
        borderRadius={10} 
        boxShadow="md" 
        w="lg" 
        bg="#FFFFFF"
        align="center"
      >
        {/* Centered Logo */}
        <Box display="flex" justifyContent="center" mb={6} w="100%">
          <img 
            src="/logo2.png" 
            alt="Qualiphy Logo" 
            style={{ height: "auto", width: "250px" }}
          />
        </Box>

        {/* Centered Title with Spacing */}
        <Text 
          fontSize="xl" 
          fontWeight="bold" 
          mb={4} 
          textAlign="center"
        >
          Qualiphy - Zenoti Integration
        </Text>

        <Text 
          fontSize="13px" 
          fontWeight="light" 
          mb={6} 
          textAlign="center"
        >
          The Zenoti-Qualiphy integration combines powerful healthcare management with seamless POS tools to streamline operations, automate workflows, and enhance client experiences.  
          <Link href="https://zenoti-qualiphy.zendesk.com/hc/en-us/articles/30275522712091-How-Do-I-Setup-my-Zenoti-account-with-Qualiphy" color="blue.500" isExternal ml={1}>
            Learn more
          </Link>
        </Text>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form style={{ width: '100%' }}>
              <Field name="email">
                {({ field }) => (
                  <FormControl mb={4} isInvalid={errors.email && touched.email}>
                    <FormLabel fontSize="md" fontWeight="semibold">Email</FormLabel>
                    <Input {...field} type="text" placeholder="Enter your Email" size="lg" />
                    <ErrorMessage name="email">
                      {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="password">
                {({ field }) => (
                  
                  <FormControl mb={6} isInvalid={errors.password && touched.password}>
                    <FormLabel fontSize="md" fontWeight="semibold">Password</FormLabel>
                    <Input {...field} type="password" placeholder="Enter your password" size="lg" />
                    <ErrorMessage name="password">
                      {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
               
              {/* Full Width Button */}
              <Button
                backgroundColor="#3D2B64"
                color="white"
                _hover={{ backgroundColor: "#2C1F4A" }}
                size="lg"
                type="submit"
                isLoading={isLoading}
                loadingText="Logging in..."
                width="full"
                mb={4}
              >
                {isLoading ? <Spinner size="sm" /> : 'Login'}
              </Button>

              {/* Links Row - Now under the button */}
              <Flex justifyContent="space-between" mb={4}>
                {/* <Link as={RouterLink} to="/zenoti/forgot-password" color="blue.500" fontSize="sm">
                  Forgot Password?
                </Link> */}
                <Link as={RouterLink} to="/zenoti/register" color="blue.500" fontSize="sm">
                  Don't have an account? Sign up
                </Link>

              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Box>
  );

};

export default Login;