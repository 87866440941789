import React, { useEffect, useState } from 'react';
import { Box, Text, HStack, Input, FormControl, FormLabel, Button, useDisclosure,
         Drawer, DrawerContent, NumberInput, NumberInputField, NumberInputStepper,
        NumberIncrementStepper, NumberDecrementStepper,
        useToast, InputGroup, Tooltip, IconButton, InputRightElement
       } from '@chakra-ui/react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SidebarContent, MobileNav } from './Sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useProfile } from '../hooks/profileContext';
import apiManager from '../apiManager';
import { FaRegCopy } from "react-icons/fa";


const Settings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, fetchProfile } = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    zenotiApiKey: '',
    company: '',
    // zenotiApplicationId: '',
    zenotiSecretKey: '',
    qualiphyIntegrationKey: '',
    screeningFrequencyInMonths: 1,
  });

  useEffect(() => {
    const initializeProfile = async () => {
      await fetchProfile(); // Ensure the profile is fetched before checking its value.
    };
  
    initializeProfile();
  }, [fetchProfile]);

  // Set initial form values if editing an existing 
  useEffect(() => {
  
    if (profile) {
      setInitialValues({
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        phone: profile.phone || '',
        email: profile.email || '',
        zenotiApiKey: profile.zenotiApiKey || '',
        company: profile.company || '',
        // zenotiApplicationId: profile.zenotiApplicationId || '',
        zenotiSecretKey: profile.zenotiSecretKey || '',
        screeningFrequencyInMonths: profile.screeningFrequencyInMonths || 1,
        qualiphyIntegrationKey: profile.qualiphyIntegrationKey
      });
    }
  
  }, [profile]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // phone: Yup.string().required('Phone Number is required'),
    phone: Yup.string()
    .matches(
      /^\d{3}-\d{3}-\d{4}$/,
      'Phone number must be exactly 10 digits in the format 555-555-5555'
    )
    .required('Phone Number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    company: Yup.string().required('Company Name is required'),
    zenotiApiKey: Yup.string().required('API Key is required'),
    // zenotiApplicationId: Yup.string().optional('Application ID is required'),
    zenotiSecretKey: Yup.string().optional('Secret Key is optional'),
    screeningFrequencyInMonths: Yup.string().required('Screening Frequency is required'),
  });

  const handleSubmit = async (values , { setSubmitting, setFieldError }) => {

    try {
      const response = await apiManager.put('/zenoti/updateprofile/user', values, true);
    
      if(response.message === "User updated successfully"){
        await fetchProfile();
        toast({
          title: "User Updated Successful",
          description: "User have been updated successfully!",
          status: "success",
          duration: 1000, // 3 seconds
          isClosable: true,
        });

        setTimeout(() => {
          window.location.reload()
        }, 2000);
        
      } 
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        const message  = error.response.data.error;
        setFieldError('zenotiSecretKey', message); // Assuming error message structure matches
      } else {
        console.error('update:', error);
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }

  };

  return (
    <Box minH="100vh" bg="#F4F4F4">
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p="10">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form style={{ width: '75%' }}>
              {/* Webhook key Section */}
              <Text fontSize="xl" fontWeight="bold" mb={4}>Webhook Integration Key</Text>
              <HStack spacing={16} mb={4}>
                {/* <Field name="qualiphyIntegrationKey">
                  {({ field }) => (
                    <FormControl isInvalid={errors.qualiphyIntegrationKey && touched.qualiphyIntegrationKey}>
                     
                      <Input {...field} placeholder="Webhook Integration key" 
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      // isDisabled={true} // Disable the input
                      readOnly={true} // Make the input read-only
                      />
                      <ErrorMessage name="qualiphyIntegrationKey">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field> */}

<Field name="qualiphyIntegrationKey">
  {({ field }) => (
    <FormControl isInvalid={errors.qualiphyIntegrationKey && touched.qualiphyIntegrationKey}>
      <InputGroup>
        <Input
          {...field}
          placeholder="Webhook Integration key"
          borderWidth="1px"
          borderColor="gray.400"
          focusBorderColor="blue.500"
          // readOnly={true}
          disabled
        />
        <InputRightElement>
          <Tooltip label="Copy to clipboard" hasArrow placement="top">
            <IconButton
              aria-label="Copy to clipboard"
              icon={<FaRegCopy />} // Import an appropriate icon, e.g., from Chakra UI or another library
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(field.value);
                toast({
                  title: "Copied!",
                  description: "Integration key has been copied to clipboard.",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }}
            />
          </Tooltip>
        </InputRightElement>
      </InputGroup>
      <ErrorMessage name="qualiphyIntegrationKey">
        {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
      </ErrorMessage>
    </FormControl>
  )}
</Field>

              </HStack>
              

              {/* Basic Info Section */}
              <Text fontSize="xl" fontWeight="bold" mb={4}>Basic Information</Text>
              <HStack spacing={16} mb={4}>
                <Field name="firstName">
                  {({ field }) => (
                    <FormControl isInvalid={errors.firstName && touched.firstName}>
                      <FormLabel>First Name<Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter First Name"
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      />
                      <ErrorMessage name="firstName">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field }) => (
                    <FormControl isInvalid={errors.lastName && touched.lastName}>
                      <FormLabel>Last Name<Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter Last Name" 
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      />
                      <ErrorMessage name="lastName">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <HStack spacing={16} mb={4}>
                {/* <Field name="phone">
                  {({ field }) => (
                    <FormControl isInvalid={errors.phone && touched.phone}> 
                      <FormLabel>Phone<Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter Phone Number"
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      />
                      <ErrorMessage name="phone">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field> */}

              <Field name="phone">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                    <FormLabel>
                      Phone <Text as="span" color="red.500">*</Text>
                    </FormLabel>
                    <Input
                      {...field}
                      value={field.value} // Controlled value
                      onChange={(e) => {
                        // Auto-formatting the phone number
                        const input = e.target.value.replace(/\D/g, ''); // Remove non-digits
                        const formatted = input
                          .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') // Apply 555-555-5555 format
                          .substr(0, 12); // Limit to 12 characters
                        form.setFieldValue('phone', formatted);
                      }}
                      placeholder="555-555-5555"
                      maxLength={12} // Limit input to formatted length
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Default border color
                      focusBorderColor="blue.500" // Focus border color
                    />
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <Text color="red.500" fontSize="sm">
                          {msg}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>

                <Field name="email">
                  {({ field }) => (
                    <FormControl isInvalid={errors.email && touched.email}>
                      <FormLabel>Email<Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter Email" 
                         borderWidth="1px" // Increase border size
                         borderColor="gray.400" // Set default border color
                         focusBorderColor="blue.500" // Set focus border color
                         />
                      <ErrorMessage name="email">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
          
              {/* Zenoti Keys Section */}
              <Text fontSize="lg" fontWeight="bold" mb={6}>Zenoti Keys & Details</Text>
               <Field name="zenotiApiKey">
                  {({ field }) => (
                    <FormControl isInvalid={errors.zenotiApiKey && touched.zenotiApiKey} mb={4}>
                      <FormLabel>Zenoti API Key<Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter API Key" 
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      />
                      <ErrorMessage name="zenotiApiKey">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              <HStack spacing={16} mb={4}>
                <Field name="company">
                {({ field }) => (
                  <FormControl isInvalid={errors.company && touched.company}>
                    <FormLabel>Company Name<Text as="span" color="red.500">*</Text></FormLabel>
                    <Input {...field} placeholder="Enter Company Name" 
                    borderWidth="1px" // Increase border size
                    borderColor="gray.400" // Set default border color
                    focusBorderColor="blue.500" // Set focus border color
                    />
                    <ErrorMessage name="company">
                      {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
                {/* <Field name="zenotiApplicationId">
                  {({ field }) => (
                    <FormControl isInvalid={errors.zenotiApplicationId && touched.zenotiApplicationId} >
                      <FormLabel>Zenoti Application ID</FormLabel>
                      <Input {...field} placeholder="Enter Application ID" 
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      />
                      <ErrorMessage name="zenotiApplicationId">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field> */}
              </HStack>
              <HStack spacing={16} mb={6}>
                <Field name="zenotiSecretKey">
                  {({ field }) => (
                    <FormControl isInvalid={errors.zenotiSecretKey && touched.zenotiSecretKey}>
                      <FormLabel>Zenoti Secret Key</FormLabel>
                      <Input {...field} placeholder="Enter Secret Key" 
                      borderWidth="1px" // Increase border size
                      borderColor="gray.400" // Set default border color
                      focusBorderColor="blue.500" // Set focus border color
                      />
                      <ErrorMessage name="zenotiSecretKey">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="screeningFrequencyInMonths">
                  {({ field, form }) => (
                      <FormControl isInvalid={errors.screeningFrequencyInMonths && touched.screeningFrequencyInMonths}>
                        <FormLabel>Screening Frequency<Text as="span" color="red.500">*</Text></FormLabel>
                        <NumberInput
                          {...field}
                          min={1}
                          max={12}
                          value={field.value}
                          onChange={(value) => form.setFieldValue("screeningFrequencyInMonths", value)}
                         borderColor="gray.400" // Set default border color
                         focusBorderColor="blue.500" // Set focus border color
                        >
                          <NumberInputField placeholder="Screening Frequency (in months)" />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <ErrorMessage name="screeningFrequencyInMonths">
                          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                        </ErrorMessage>
                      </FormControl>
                    )}
                </Field>
              </HStack>

              <Button type="submit" color="white" mt={6} backgroundColor="#3D2B64">
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Settings;
