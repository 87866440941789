import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Drawer,
  DrawerContent,
  useDisclosure,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  UnorderedList,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Tooltip,
  CloseButton,
  Badge
} from '@chakra-ui/react';
import { FiSearch, FiPlus,FiEdit2 } from 'react-icons/fi';
import { PiDotsThreeCircleVertical } from "react-icons/pi";
import { MdOutlineRemoveCircleOutline, MdBlock, MdCheck } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { SidebarContent, MobileNav } from './Sidebar';
import apiManager from '../apiManager';



const Services = () => {
  const [services, setServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zenotiCenters, setZenotiCenters] = useState([]);
  const [zenotiServices, setZenotiService] = useState([]);
  const [examList, setExamList] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [apiKey, setApiKey] = useState([]);
  
  const [isPopupOpen, setIsPopupOpen] = React.useState(false); // State for modal visibility
  const [missingCenterName, setMissingCenterName] = React.useState(''); // State to hold the missing center name
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [serviceIdToDelete, setServiceIdToDelete] = useState(null);
  const cancelRef = React.createRef();

  const [isEditMode, setIsEditMode] = useState(false); // Edit mode flag
  // For editing, this data should come from wherever you're fetching or storing the service data
  const [existingServiceData, setExistingServiceData] = useState({
    center: '',
    service: '',
    exams: []
  });


  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedExams, setSelectedExams] = useState([]);

  const [isActionOpen, setIsActionOpen] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        
        const response = await apiManager.get(`/zenoti/serviceList?page=${currentPage}&limit=10&searchName=${searchQuery}`, true);
    
        if (response.docs && response.docs.length > 0) {
          setServices(response.docs);
          setTotalPages(response.totalPages);
        } else {
          setServices([]);
          setTotalPages(0);
          setError("No services found.");
        }
        setIsLoading(false);
      } catch (error) {
        // setError(error.message || "Failed to fetch services.");
        setError("No services found.");
        setIsLoading(false);
      }
    };


    const fetchZenotiCenters = async () => {
        try {
          const response = await apiManager.get(`/zenoti/centers?page=1&limit=1000`, true);
          if (response.docs) {
            setZenotiCenters(response.docs);
          //   const activeCenters = response.docs.filter(center => center.isActive);
          //  setZenotiCenters(activeCenters);
          }
        } catch (err) {
          console.error('Failed to fetch Zenoti Centers:', err);
        }
      };

    fetchData();
    fetchZenotiCenters();
  }, [currentPage, searchQuery]);


  const handleRemoveService = (serviceId) => {
    setServiceIdToDelete(serviceId);
    setIsDeleteOpen(true);
  };

  const handleDeactivateQuiz = (serviceId) => {
    setServiceIdToDelete(serviceId);
    setIsDeleteOpen(true);
  };

  const openAddCenterModal = () => setIsModalOpen(true);
  const closeAddCenterModal = () => {
    setSelectedExams([]);
    setSelectedService('')
    setSelectedCenter('')
    setApiKey([]);
    setIsModalOpen(false);
    setIsEditMode(false); // Reset to add mode after closing the modal
  };


// Updated openEditModal function
const openEditModal = async (serviceData) => {
  try {
    // Find the center
    const center = zenotiCenters.find(c => c.zenotiCenterName === serviceData.zenotiCenterName);
    
    if (center) {
      // Set center first
      setSelectedCenter(center.zenotiCenterId);
      
      // Fetch services for the center
      await fetchZenotiServices(center.zenotiCenterId);
      
      // Find the service
      const service = zenotiServices.find(s => s.name === serviceData.zenotiServiceName);
      
      // Set service
      setSelectedService(service?.id);
      
      // Set selected exams 
      const selectedExamObjects = serviceData.qualiphyExams.map(selectedExam => {
        // Find the full exam object from examList
        const fullExamObject = examList?.find(exam => 
          exam?.title === selectedExam?.title && exam?.value === selectedExam?.id
        );
        
        return fullExamObject || {
          value: selectedExam.id,
          title: selectedExam.title
        };
      });
      
      setSelectedExams(selectedExamObjects);
      
      // Set edit mode and open modal
      setIsEditMode(true);
      setIsModalOpen(true);
    } else {
      console.error('Center not found for name:', serviceData.zenotiCenterName);
      setMissingCenterName(serviceData.zenotiCenterName); // Set the missing center name
      setIsPopupOpen(true); // Open the modal
  
    }
  } catch (error) {
    console.error('Error opening edit modal:', error);
  }
};



  // Updated removeExam function
const removeExam = (examToRemove) => {
  setSelectedExams(prevExams => 
    prevExams.filter(exam => exam?.value !== examToRemove.value)
  );
};

// Updated handleAddService
const handleAddService = async () => {
  const service = zenotiServices.find(service => service.id === selectedService);
  
  const payload = {
    centerId: selectedCenter,
    serviceData: service,
    examsData: selectedExams.map(exam => ({
      id: exam?.id || exam?.value,
      title: exam?.title
    }))
  };
  
  try {
    if (isEditMode) {
      await apiManager.put(`/zenoti/updateService`, payload, true);
      setIsModalOpen(false);
      window.location.reload();
    } else {
      await apiManager.post('/zenoti/addServices', payload, true);
      setIsModalOpen(false);
      window.location.reload();
    }
  } catch (err) {
    if(err?.response?.data?.message === "This service is already added for this center. Please activate the service and map to exams as needed."){
      console.log('ERROR', error)
        setError(err.response.data.message);

              // Clear the error after 5 seconds
      setTimeout(() => {
        setError("");
      }, 4000);
    }
    console.error('Failed to submit service', err);
  }
};

  const onDeleteConfirm = async () => {
    let center = zenotiCenters.find(center => center.zenotiCenterName
      === serviceIdToDelete.zenotiCenterName);
    
    let payload = {
      centerId: center?.zenotiCenterId,
      serviceId: serviceIdToDelete?.id
    }
    console.log('Payload', payload)
    try {
      const response = await apiManager.delete(`/zenoti/removeService`, payload, true);
      if (response.success === true) {
        window.location.reload();
      } else {
        setError("Failed to delete service.");
      }
    } catch (error) {
      setError(error.message || "Failed to delete service.");
    }
    setIsDeleteOpen(false);
  };

  // const handleSearchChange = (event) => {
  //   setSearchQuery(event.target.value);
  //   setCurrentPage(1);
  // };

  const fetchZenotiServices = async (centerId) => {
    
    let payload = {
      centerId: centerId
    }
    try {
      const response = await apiManager.get(`/zenoti/getCenterServices?centerId=${centerId}`,payload, true);
      const examList = await apiManager.get(`/zenoti/getExams?centerId=${centerId}`,payload, true);
      if (response.data) { setZenotiService(response.data) }
      if (examList?.data) { setExamList(examList?.data?.exams); }
    } catch (err) {
      console.error('Failed to fetch Zenoti Centers:', err);
    }
  };

  // const removeApiKey = (keyToRemove) => {
  //   setSelectedExams(selectedExams.filter((key) => key !== keyToRemove));
  // };

  // const handleSelectExams = (event) => {
  //   const selectedValue = event.target.value;
  //   if (selectedValue && !selectedExams.includes(selectedValue)) {
  //     setSelectedExams([...selectedExams, selectedValue]);
  //   }
  // };

  const handleSelectExams = (event) => {
    const selectedValue = event.target.value;
    
    if (selectedValue) {
      // Find the full exam object from examList
      const selectedExam = examList.find(exam => exam?.title === selectedValue);
      // Only add if it's not already selected
      if (selectedExam && !selectedExams.some(exam => exam?.title === selectedValue)) {
        setSelectedExams([...selectedExams, selectedExam]);
      }
    }
  };

  // const handleSelectExams = (e) => {
  //   const selectedOptions = Array.from(e.target.selectedOptions);
  //   const selectedValues = selectedOptions.map(option =>
  //     examList.find(exam => exam?.value === option.value) || { value: option.value, title: option.text }
  //   );
    
  //   setSelectedExams(selectedValues);
  // };

  // Function to handle opening the alert
const handleServiceAction = (service) => {
  setSelectedService(service);
  setIsActionOpen(true);
};

// Function to confirm the action
const onConfirmAction = async () => {
  if (!selectedService) return;

  try {
    // Toggle the service's active state
    const updatedStatus = selectedService.isActive ? 0 : 1;
    await updateServiceStatus(selectedService.id, updatedStatus); // API call function
    setIsActionOpen(false);
    // Update the UI based on success
    console.log(`Service ${updatedStatus === 1 ? "activated" : "deactivated"} successfully`);
  } catch (error) {
    console.error("Error updating service status:", error);
  }
};

// API function for toggling service status
const updateServiceStatus = async (id, status) => {
  let payload = {
    serviceId: id,
    isActive: status
  }
  const response = await apiManager.post(`/zenoti/updateServiceStatus`,payload, true);
  console.log('RESSS', response)
  if (response.success === true) {
    window.location.reload();
  } else {
    setError("Failed to delete service.");
  }
};

  return (
    <Box minH="100vh" bg="#F4F4F4">
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p="6">
        <Flex justify="space-between" align="center" mb={6}>
          <Text fontSize="3xl" fontWeight="bold">Zenoti Services</Text>
          <Button 
            leftIcon={<FiPlus />} 
            bg="#52307C" 
            color="white" 
            _hover={{ bg: "#3E2562" }}
            onClick={openAddCenterModal}
          >
            Add Service
          </Button>
        </Flex>

        {/* <Flex justify="space-between" mb={6} gap={4}>
          <InputGroup maxW="320px">
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.400" />
            </InputLeftElement>
            <Input 
              placeholder="Search..." 
              bg="white" 
              border="1px solid"
              borderColor="gray.200"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>

        </Flex> */}

        <Box bg="white" borderRadius="md" overflow="hidden" boxShadow="sm">
          {isLoading ? (
            <Flex justify="center" align="center" h="200px">
              <Spinner size="xl" />
            </Flex>
          ) : services.length > 0 ? (
          
            <Table variant="simple">
              <Thead bg="#E4E1F5" height="60px">
                <Tr>
                  <Th>Id</Th>
                  <Th>Center Name</Th>
                  <Th>Service Name</Th>
                  <Th>Qualiphy Exams</Th>
                  <Th>Status</Th>
                  <Th>Date Added</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {services?.map((service) => (
                  <Tr key={service?._id}>
                    <Td>{service?.id}</Td>
                    <Td>{service?.zenotiCenterName}</Td>
                    <Td>{service?.zenotiServiceName}</Td>
                    <Td>
                      {service?.qualiphyExams && service?.qualiphyExams.length > 0 ? (
                        <UnorderedList>
                          {service?.qualiphyExams?.map((exam, index) => (
                            <ListItem key={index}>{exam?.title}</ListItem>
                          ))}
                        </UnorderedList>
                      ) : (
                        "No Exams"
                      )}
                    </Td>
                    <Td>
                      {service?.isActive === 1 ? (
                        <Badge colorScheme="green" px="2" py="1" borderRadius="md">
                          Active
                        </Badge>
                      ) : (
                        // <Badge colorScheme="red" px="2" py="1" borderRadius="md">
                        //   Deactive
                        // </Badge>
                        <Badge colorScheme="red" px="2" py="1" borderRadius="md">
                          InActive
                        </Badge>
                      )}
                    </Td>
                    <Td>{service?.dateAdded}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<PiDotsThreeCircleVertical style={{ fontSize: "24px" }} />}
                            aria-label="Options"
                            variant="unstyled"
                            _hover={{ bg: "white" }}
                          //   _focus={{ boxShadow: "outline" }}
                          />
                          <MenuList 
                            bg="white" 
                            boxShadow="lg" 
                            borderRadius="md" 
                            py={2} 
                            minWidth="150px"
                          >
                            <MenuItem 
                              onClick={() => openEditModal(service)} 
                              icon={<FiEdit2 />} 
                              _hover={{ bg: "gray.50" }}
                              fontWeight="medium"
                            >
                              Edit
                            </MenuItem>
                            <MenuItem 
                              onClick={() => handleRemoveService(service)} 
                              icon={<MdOutlineRemoveCircleOutline />} 
                              _hover={{ bg: "red.50", color: "red.500" }}
                              fontWeight="medium"
                            >
                              Remove
                            </MenuItem>
                            {/* <MenuItem 
                              onClick={() => handleDeactivateQuiz(service)} 
                              icon={<MdBlock />} 
                              _hover={{ bg: "yellow.50", color: "yellow.600" }}
                              fontWeight="medium"
                            >
                              Deactivate
                            </MenuItem> */}
                            <MenuItem
                                onClick={() => handleServiceAction(service)}
                                icon={service?.isActive ? <MdBlock /> : <MdCheck />}
                                _hover={{ bg: "yellow.50", color: "yellow.600" }}
                                fontWeight="medium"
                              >
                                {service?.isActive ? "Inactivate" : "Activate"}
                              </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>


                  </Tr>
                ))}
              </Tbody>
            </Table>

          ) : (
            <Flex justify="center" align="center" h="200px">
              <Text>{error || "No services found."}</Text>
            </Flex>
          )}
        </Box>

        <Flex p={4} justify="center" align="center" borderTop="1px" borderColor="gray.200">
            <ButtonGroup variant="outline" spacing={1}>
              <Button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                isDisabled={currentPage === 1}
                size="sm"
              >
                Prev
              </Button>
              {[...Array(totalPages)].map((_, i) => (
                <Button
                  key={i + 1}
                  onClick={() => setCurrentPage(i + 1)}
                  bg={currentPage === i + 1 ? "#52307C" : "transparent"}
                  color={currentPage === i + 1 ? "white" : "black"}
                  size="sm"
                >
                  {i + 1}
                </Button>
              ))}
              <Button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                isDisabled={currentPage === totalPages}
                size="sm"
              >
                Next
              </Button>
            </ButtonGroup>
          </Flex>
      </Box>


      <Modal isOpen={isModalOpen} onClose={closeAddCenterModal}>
  <ModalOverlay />
  <ModalContent
    margin="auto"
    transform="translateY(-50%)"
    maxWidth="500px"
  >
    <ModalHeader>{isEditMode ? "Edit Zenoti Service" : "Add Zenoti Service"}</ModalHeader>
    <ModalBody>
      <Flex direction="column" gap={4}>
        {/* Zenoti Center Dropdown */}
        <Select
          placeholder="Select Zenoti Center"
          value={selectedCenter}
          onChange={(e) => {
            const selectedCenterId = e.target.value;
            setSelectedCenter(selectedCenterId);
            if (selectedCenterId) {
              fetchZenotiServices(selectedCenterId);
            } else {
              setZenotiService([]); 
            }
          }}
        >
          {zenotiCenters?.map((center) => (
            <option key={center?.zenotiCenterId} value={center?.zenotiCenterId}>
              {center.zenotiCenterName}
            </option>
          ))}
        </Select>

        {/* Zenoti Service Dropdown */}
        <Select
          placeholder="Select Zenoti Service"
          value={selectedService}
          onChange={(e) => setSelectedService(e.target.value)}
          disabled={!selectedCenter}
        >
          {zenotiServices?.map((service) => (
            <option key={service?.id} value={service?.id}>
              {service?.name}
            </option>
          ))}
        </Select>

        <Flex direction="column" gap={4}>
    
   {/* Exam Dropdown with multi-select */}
<Box position="relative">
  <Select
    placeholder="Select Exams"
    // multiple
    bg="white"
    border="1px solid"
    borderColor="gray.300"
    borderRadius="md"
    boxShadow="sm"
    size="md"
    // value={selectedExams.map(exam => exam?.value)}
    value={selectedExams.map(exam => exam?.title)}
    onChange={handleSelectExams}
    disabled={!selectedCenter}
  >
    {examList?.map((exam) => (
      <option
        key={exam?.title}
        value={exam?.title}
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        {exam?.title}
      </option>
    ))}
  </Select>
</Box>

{/* Display Selected Items */}
<Flex wrap="wrap" gap={2}>
  {selectedExams.map((exam) => (
    <Tag
      key={exam?.title}
      size="lg"
      variant="solid"
      colorScheme="blue"
      borderRadius="full"
      display="flex"
      alignItems="center"
    >
      <Tooltip label={exam?.title} placement="top" hasArrow>
        <Text noOfLines={1} maxWidth="200px">
          {exam?.title}
        </Text>
      </Tooltip>
      <CloseButton
        size="sm"
        ml={2}
        onClick={() => {
          setSelectedExams(prevExams =>
            prevExams.filter(e => e.value !== exam?.value)
          );
        }}
        aria-label="Remove"
      />
    </Tag>
  ))}
</Flex>

{error && (
                <Text color="red.500" fontSize="sm" mt={2}>
                  {error}
                </Text>
                )}

       
        </Flex>
        
      </Flex>
    </ModalBody>
    <ModalFooter>
      <Button onClick={closeAddCenterModal} variant="outline" mr={3}>
        Cancel
      </Button>
      <Button
        onClick={handleAddService}
        bg="#52307C"
        color="white"
        _hover={{ bg: '#3E2562' }}
      >
        {isEditMode ? "Save Changes" : "Submit"}
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>


      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Service
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this Service?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
  isOpen={isActionOpen}
  leastDestructiveRef={cancelRef}
  onClose={() => setIsActionOpen(false)}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {selectedService?.isActive ? "Inactivate Service" : "Activate Service"}
      </AlertDialogHeader>
      <AlertDialogBody>
        Are you sure you want to {selectedService?.isActive ? "Inactivate" : "activate"} this service?
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={() => setIsActionOpen(false)}>
          Cancel
        </Button>
        <Button colorScheme="red" onClick={onConfirmAction} ml={3}>
          {selectedService?.isActive ? "Inactivate" : "Activate"}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>;


<AlertDialog
  isOpen={isPopupOpen}
  onClose={() => setIsPopupOpen(false)}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
        Center Not Found
      </AlertDialogHeader>
      <AlertDialogBody textAlign="center">
        The center "<strong>{missingCenterName}</strong>" was not found in your Center List. Please add or activate this center to your Center list to proceed.
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button onClick={() => setIsPopupOpen(false)} colorScheme="blue">
          OK
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>



    </Box>
  );
};

export default Services;