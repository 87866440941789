import { Box, Button, Flex, Input, Text, FormLabel, FormControl, Link , HStack, NumberInput, NumberInputField,NumberInputStepper,  NumberIncrementStepper, NumberDecrementStepper, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate ,Link as RouterLink} from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import apiManager from '../apiManager';

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    company: '',
    zenotiApiKey: '',
    // zenotiApplicationId: '',
    zenotiSecretKey: '',
    screeningFrequencyInMonths: '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // phone: Yup.string().required('Phone Number is required'),
    phone: Yup.string()
    .matches(
      /^\d{3}-\d{3}-\d{4}$/,
      'Phone number must be exactly 10 digits in the format 555-555-5555'
    )
    .required('Phone Number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    company: Yup.string().required('Company Name is required'),
    zenotiApiKey: Yup.string().required('API Key is required'),
    // zenotiApplicationId: Yup.string().optional('Application ID is required'),
    zenotiSecretKey: Yup.string().optional('Secret Key is required'),
    screeningFrequencyInMonths: Yup.string().required('Screening Frequency is required'),
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    setIsLoading(true);
    try {
      const response = await apiManager.post('/zenoti/register/user', values);
      if(response.message === 'User registered successfully'){
        toast({
          title: "Registration Successful",
          description: "You have been registered successfully!",
          status: "success",
          duration: 3000, // 3 seconds
          isClosable: true,
        });

         // Navigate to login after 3 seconds
         setTimeout(() => {
          navigate('/zenoti/login');
        }, 4000);
      }
      
    } catch (error) {
      if (error.response && error.response.data) {
        const { message } = error.response.data;
        setFieldError('password', message); // Assuming error message structure matches
      } else {
        console.error('Login error:', error);
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box w="100%" h="110vh" display="flex" justifyContent="center" alignItems="center" bg="#E4E1F5">
      <Flex direction="column" p={10} borderRadius={10} boxShadow="md" bg="#FFFFFF" align="center" w="50%">
        <Box display="flex" justifyContent="center" mb={4} w="100%">
          <img src="/logo2.png" alt="Qualiphy Logo" style={{ height: "auto", width: "250px" }} />
        </Box>
        <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">
          Qualiphy - Zenoti Integration
        </Text>
        <Text 
          fontSize="13px" 
          fontWeight="light" 
          mb={4} 
          textAlign="center"
        >
          The Zenoti-Qualiphy integration combines powerful healthcare management with seamless POS tools to streamline operations, automate workflows, and enhance client experiences.  
          <Link href="https://zenoti-qualiphy.zendesk.com/hc/en-us/articles/30275522712091-How-Do-I-Setup-my-Zenoti-account-with-Qualiphy" color="blue.500" isExternal ml={1}>
            Learn more
          </Link>
        </Text>
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form style={{ width: '90%' }}>
              {/* Basic Info Section */}
              <Text fontSize="xl" fontWeight="bold" mb={4}>Basic Information</Text>
              <HStack spacing={16} mb={4}>
                <Field name="firstName">
                  {({ field }) => (
                    <FormControl isInvalid={errors.firstName && touched.firstName}>
                      <FormLabel>First Name <Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter First Name" />
                      <ErrorMessage name="firstName">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field }) => (
                    <FormControl isInvalid={errors.lastName && touched.lastName}>
                      <FormLabel>Last Name <Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter Last Name" />
                      <ErrorMessage name="lastName">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <HStack spacing={16} mb={4}>
                {/* <Field name="phone">
                  {({ field }) => (
                    <FormControl isInvalid={errors.phone && touched.phone}> 
                      <FormLabel>Phone <Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter Phone Number" />
                      <ErrorMessage name="phone">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field> */}
                <Field name="phone">
  {({ field, form }) => {
    const handleChange = (e) => {
      const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
      if (input.length <= 10) {
        const formattedPhone = input
          .replace(/^(\d{3})(\d)/, '$1-$2') // Add the first hyphen
          .replace(/-(\d{3})(\d)/, '-$1-$2'); // Add the second hyphen
        form.setFieldValue('phone', formattedPhone);
      }
    };

    return (
      <FormControl isInvalid={form.errors.phone && form.touched.phone}>
        <FormLabel>
          Phone <Text as="span" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...field}
          value={field.value} // Controlled value
          onChange={handleChange} // Auto-format input
          placeholder="555-555-5555"
          maxLength={12} // Prevent entering more than 12 characters (10 digits + 2 hyphens)
        />
        <ErrorMessage name="phone">
          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
        </ErrorMessage>
      </FormControl>
    );
  }}
</Field>

                <Field name="email">
                  {({ field }) => (
                    <FormControl isInvalid={errors.email && touched.email}>
                      <FormLabel>Email <Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter Email" />
                      <ErrorMessage name="email">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <Field name="password">
                {({ field }) => (
                  <FormControl mb={6} isInvalid={errors.password && touched.password} >
                    <FormLabel>Password <Text as="span" color="red.500">*</Text></FormLabel>
                    <Input {...field} type="password" placeholder="Enter Password" />
                    <ErrorMessage name="password">
                      {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>

              {/* Zenoti Keys Section */}
              <Text fontSize="lg" fontWeight="bold" mb={6}>Zenoti Keys & Details</Text>
               <Field name="zenotiApiKey">
                  {({ field }) => (
                    <FormControl isInvalid={errors.zenotiApiKey && touched.zenotiApiKey} mb={4}>
                      <FormLabel>Zenoti API Key <Text as="span" color="red.500">*</Text></FormLabel>
                      <Input {...field} placeholder="Enter API Key" />
                      <ErrorMessage name="zenotiApiKey">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              <HStack spacing={16} mb={4}>
                <Field name="company">
                {({ field }) => (
                  <FormControl isInvalid={errors.company && touched.company}>
                    <FormLabel>Company Name <Text as="span" color="red.500">*</Text></FormLabel>
                    <Input {...field} placeholder="Enter Company Name" />
                    <ErrorMessage name="company">
                      {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
                {/* <Field name="zenotiApplicationId">
                  {({ field }) => (
                    <FormControl isInvalid={errors.zenotiApplicationId && touched.zenotiApplicationId} >
                      <FormLabel>Zenoti Application ID </FormLabel>
                      <Input {...field} placeholder="Enter Application ID" />
                      <ErrorMessage name="zenotiApplicationId">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field> */}
              </HStack>
              <HStack spacing={16} mb={6}>
                <Field name="zenotiSecretKey">
                  {({ field }) => (
                    <FormControl isInvalid={errors.zenotiSecretKey && touched.zenotiSecretKey}>
                      <FormLabel>Zenoti Secret Key</FormLabel>
                      <Input {...field} placeholder="Enter Secret Key" />
                      <ErrorMessage name="zenotiSecretKey">
                        {msg => <Text color="red.500" fontSize="sm">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="screeningFrequencyInMonths">
                  {({ field, form }) => (
                      <FormControl isInvalid={errors.screeningFrequencyInMonths && touched.screeningFrequencyInMonths}>
                        <FormLabel>Screening Frequency <Text as="span" color="red.500">*</Text></FormLabel>
                        <NumberInput
                          {...field}
                          min={1}
                          max={12}
                          value={field.value}
                          onChange={(value) => form.setFieldValue("screeningFrequencyInMonths", value)}
                        >
                          <NumberInputField placeholder="Screening Frequency (in months)" />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <ErrorMessage name="screeningFrequencyInMonths">
                          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                        </ErrorMessage>
                      </FormControl>
                    )}

                </Field>
              </HStack>

              <Button
                backgroundColor="#3D2B64"
                color="white"
                _hover={{ backgroundColor: "#2C1F4A" }}
                size="lg"
                type="submit"
                isLoading={isLoading}
                loadingText="Submitting..."
                width="full"
                mb={4}
              >
                Register
              </Button>

              {/* Links Row - Now under the button */}
              <Flex justifyContent="space-between" mb={4}>
                            {/* <Link as={RouterLink} to="/zenoti/forgot-password" color="blue.500" fontSize="sm">
                              Forgot Password?
                </Link> */}
                <Link as={RouterLink} to="/zenoti/login" color="blue.500" fontSize="sm">
                  Already have an account? Login
                </Link>

              </Flex>

            </Form>
          )}
        </Formik>
      </Flex>
    </Box>
  );
};


export default SignUp;