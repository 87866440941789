import { Box, Button, Flex, Input, Text, FormControl, FormLabel, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import apiManager from '../apiManager';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [resetError, setResetError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  const initialValues = {
    password: '',
    confirmPassword: ''
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    try {
      await apiManager.post('/reset-password', { token, password: values.password });
      setResetSuccess(true);
    } catch (error) {
      console.error('Password reset error:', error);
      setResetError('Password reset failed. Please try again.');
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center"  bg="#E4E1F5">
      <Flex direction="column" p={8} borderRadius={10} boxShadow="md" w="lg" bg="white">
         {/* Centered Logo */}
         <Box display="flex" justifyContent="center" mb={6} w="100%">
          <img 
            src="/logo2.png" 
            alt="Qualiphy Logo" 
            style={{ height: "auto", width: "250px" }}
          />
        </Box>

        <Text 
          fontSize="xl" 
          fontWeight="bold" 
          mb={4} 
          textAlign="center"
        >
          Qualiphy - Zenoti Integration
        </Text>

        <Text fontSize="2xl" fontWeight="medium" mb={7} textAlign="center">Reset Password</Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Field name="password">
              {({ field, form }) => (
                <FormControl mb={4} isInvalid={form.errors.password && form.touched.password}>
                  <FormLabel fontSize="md" fontWeight="semibold">New Password</FormLabel>
                  <Input {...field} type="password" placeholder="Enter your new password" size="lg" />
                  <ErrorMessage name="password" component={Text} color="red.500" fontSize="sm" />
                </FormControl>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ field, form }) => (
                <FormControl mb={8} isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}>
                  <FormLabel fontSize="md" fontWeight="semibold">Confirm Password</FormLabel>
                  <Input {...field} type="password" placeholder="Confirm your new password" size="lg" />
                  <ErrorMessage name="confirmPassword" component={Text} color="red.500" fontSize="sm" />
                </FormControl>
              )}
            </Field>
            {resetError && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {resetError}
              </Alert>
            )}
            {resetSuccess && (
              <Alert status="success" mb={4}>
                <AlertIcon />
                Password reset successfully!
              </Alert>
            )}
            <Button
              backgroundColor="#3D2B64"
              color="white"
              size="lg"
              _hover={{ backgroundColor: "#2C1F4A" }}
              type="submit"
              width="full"
              isLoading={isLoading}
              loadingText="Resetting password..."
            >
              {isLoading ? <Spinner size="sm" /> : 'Reset Password'}
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Box>
  );
};

export default ResetPassword;
