// ProfileContext.js
import React, { useState, createContext, useContext, useEffect } from 'react';
import useAuth from './useAuth'; // Import your existing auth context
import apiManager from '../apiManager';

const ProfileContext = createContext();

export function ProfileProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState(null);

  // Use the auth context to check authentication status
  const { isAuthenticated } = useAuth();

  const fetchProfile = async () => {
    // Only fetch if authenticated and not already loading or loaded
    if (!isAuthenticated || isProfileLoading || profile) return;

    try {
      setIsProfileLoading(true);
      setProfileError(null);

      const response = await apiManager.get(`/zenoti/profile/user`, true);
      
      if (response.user) {
        setProfile(response.user);
      }
    } catch (error) {
      console.error('Profile fetch error:', error);
      setProfileError(error);
    } finally {
      setIsProfileLoading(false);
    }
  };

  const clearProfile = () => {
    setProfile(null);
    setIsProfileLoading(false);
    setProfileError(null);
  };

  // Fetch profile when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchProfile();
    } else {
      clearProfile();
    }
  }, [isAuthenticated]);

  return (
    <ProfileContext.Provider value={{ 
      profile, 
      isProfileLoading,
      profileError,
      fetchProfile,
      clearProfile
    }}>
      {children}
    </ProfileContext.Provider>
  );
}

// Custom hook for easy access
export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
