import axios from 'axios';
import { apiHost } from './constants';

const apiManager = {
  async request(endpoint, method, data, isAuthenticated) {
    const headers = {};
    if (isAuthenticated) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        throw new Error('No token found');
      }
      headers.Authorization = `Bearer ${token}`;
    }

    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    
    const url = `${apiHost}${endpoint}`;
    // const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    try {
      const response = await axios({
        method,
        url: url,
        data,
        headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  get(endpoint, isAuthenticated) {
    return this.request(endpoint, 'GET', null, isAuthenticated);
  },

  post(endpoint, data, isAuthenticated) {
    return this.request(endpoint, 'POST', data, isAuthenticated);
  },

  put(endpoint, data, isAuthenticated) {
    return this.request(endpoint, 'PUT', data, isAuthenticated);
  },

  delete(endpoint, data, isAuthenticated) {
    return this.request(endpoint, 'DELETE', data, isAuthenticated);
  },
};

export default apiManager;