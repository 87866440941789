import { Box, Button, Flex, Input, Text, FormControl, FormLabel, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import apiManager from '../apiManager';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    try {
      await apiManager.post('/forgot-password', { email: values.email });
      setSuccess(true);
    } catch (error) {
      console.error('Forgot password error:', error);
      setError('Failed to send reset email. Please try again.');
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center" bg="#E4E1F5">
      <Flex direction="column" p={8} borderRadius={10} boxShadow="md" w="lg" bg="white">
        {/* Centered Logo */}
        <Box display="flex" justifyContent="center" mb={6} w="100%">
          <img
            src="/logo2.png"
            alt="Qualiphy Logo"
            style={{ height: "auto", width: "250px" }}
          />
        </Box>

        <Text 
          fontSize="xl" 
          fontWeight="bold" 
          mb={4} 
          textAlign="center"
        >
          Qualiphy - Zenoti Integration
        </Text>

        <Text fontSize="2xl" fontWeight="medium" mb={7} textAlign="center">Forgot Password</Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Field name="email">
              {({ field, form }) => (
                <FormControl mb={8} isInvalid={form.errors.email && form.touched.email}>
                  <FormLabel fontSize="md" fontWeight="semibold">Email Address</FormLabel>
                  <Input {...field} type="email" placeholder="Enter your email" size="lg" />
                  <ErrorMessage name="email" component={Text} color="red.500" fontSize="sm" />
                </FormControl>
              )}
            </Field>
            {error && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
            {success && (
              <Alert status="success" mb={4}>
                <AlertIcon />
                A reset email has been sent successfully!
              </Alert>
            )}
            <Button
              backgroundColor="#3D2B64"
              color="white"
              size="lg"
              _hover={{ backgroundColor: "#2C1F4A" }}
              type="submit"
              width="full"
              isLoading={isLoading}
              loadingText="Sending email..."
            >
              {isLoading ? <Spinner size="sm" /> : 'Send Reset Email'}
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Box>
  );
};

export default ForgotPassword;
