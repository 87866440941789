import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Drawer,
  DrawerContent,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  ButtonGroup,
  Badge
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { SidebarContent, MobileNav } from './Sidebar';
import apiManager from '../apiManager';

const Screening = () => {
  const [screenings, setScreenings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiManager.get(`/zenoti/screeningList?page=${currentPage}&limit=10&searchName=${searchQuery}`, true);
    
        if (response.docs && response.docs.length > 0) {
          setScreenings(response.docs);
          // setTotalPages(100);
          setTotalPages(response.totalPages);
        } else {
          setScreenings([]);
          setTotalPages(0);
          setError("No screenings found.");
        }
        setIsLoading(false);
      } catch (error) {
        // setError(error.message || "Failed to fetch screenings.");
        setError("No screenings found");
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchQuery]);


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const pagesToShow = 5;
  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, currentPage + Math.floor(pagesToShow / 2));

  // Generate the pagination buttons
  const pageButtons = [];
  if (startPage > 1) {
    pageButtons.push(1); // Always show the first page
    if (startPage > 2) pageButtons.push('...'); // Add ellipsis if skipping pages
  }

  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(i);
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) pageButtons.push('...'); // Add ellipsis if skipping pages
    pageButtons.push(totalPages); // Always show the last page
  }

  return (
    <Box minH="100vh" bg="#F4F4F4">
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p="6">
        <Flex justify="space-between" align="center" mb={6}>
          <Text fontSize="3xl" fontWeight="bold">Screenings</Text>
        </Flex>

        {/* <Flex justify="space-between" mb={6} gap={4}>
          <InputGroup maxW="320px">
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.400" />
            </InputLeftElement>
            <Input 
              placeholder="Search..." 
              bg="white" 
              border="1px solid"
              borderColor="gray.200"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Flex> */}

        <Box bg="white" borderRadius="md" overflow="hidden" boxShadow="sm">
          {isLoading ? (
            <Flex justify="center" align="center" h="200px">
              <Spinner size="xl" />
            </Flex>
          ) : screenings.length > 0 ? (
            <Table variant="simple">
              <Thead bg="#E4E1F5" height="60px">
                <Tr>
                  <Th>Qualiphy Id</Th>
                  <Th>Center Name</Th>
                  <Th>Exam Title</Th>
                  <Th>Zenoti Service Name</Th>
                  <Th>Date</Th>
                  <Th>Guest Name</Th>
                  <Th>Guest Account Id</Th>
                  <Th>Status</Th>
                  {/* <Th>Actions</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {screenings.map((screen) => (
                  <Tr key={screen._id}>
                    <Td>{screen.qualiphyId}</Td>
                    <Td>{screen.centerName}</Td>
                    <Td>{screen.examTitle}</Td>
                    <Td>{screen.zenotiServiceName}</Td>
                    {/* <Td>{screen.date}</Td> */}
                    <Td>{new Date(screen.date).toLocaleDateString()}</Td>

                    <Td>{screen.zenotiGuestName}</Td>
                    <Td>{screen.guestAccountId}</Td>
                    {/* <Td>{screen.status}</Td> */}
                    <Td>
                      {screen?.status === 'Approved' ? (
                        <Badge colorScheme="green" px="2" py="1" borderRadius="md">
                          Approved
                        </Badge>
                      ) : (
                        <Badge colorScheme="blue" px="2" py="1" borderRadius="md">
                          Pending
                        </Badge>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex justify="center" align="center" h="200px">
              <Text>{error || "No screenings found."}</Text>
            </Flex>
          )}
        </Box>

      <Flex p={4} justify="center" align="center" borderTop="1px" borderColor="gray.200">
            <ButtonGroup variant="outline" spacing={1}>
              <Button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                isDisabled={currentPage === 1}
                size="sm"
              >
                Prev
              </Button>
              
              {pageButtons.map((page, index) => (
                <Button
                  key={index}
                  onClick={() => page !== '...' && setCurrentPage(page)}
                  bg={currentPage === page ? "#52307C" : "transparent"}
                  color={currentPage === page ? "white" : "black"}
                  size="sm"
                  isDisabled={page === '...'} // Disable the ellipsis button
                >
                  {page}
                </Button>
              ))}

              <Button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                isDisabled={currentPage === totalPages}
                size="sm"
              >
                Next
              </Button>
            </ButtonGroup>
          </Flex>
      </Box>

    </Box>
  );
};

export default Screening;
